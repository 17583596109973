type SentimentArray = [
  { name: 'positive'; value: number },
  { name: 'neutral'; value: number },
  { name: 'negative'; value: number },
];

export const minimumPositiveValue = 0.45;
const isPositiveSentiment = (scores: SentimentArray | []) => scores.some(
  ({ name, value }) => name === 'positive' && value > minimumPositiveValue,
);

export default isPositiveSentiment;
