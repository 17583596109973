import React from 'react';
import styled from '@emotion/styled';
import PropTypes, { InferProps } from 'prop-types';
import {
  EmailShareButton,
  EmailIcon,
  TwitterShareButton,
  TwitterIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  PinterestShareButton,
  PinterestIcon,
  WhatsappShareButton,
  WhatsappIcon,
  VKShareButton,
  VKIcon,
  ReactShareIcon,
} from 'react-share';
import Custom from './Custom';

const SocialWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  > div {
    flex: 0 0 30px;
    height: 30px;
    width: 30px;
    padding: 5px;
    display: inline-flex;
    justify-content: center;
    align-items: flex-end;
    cursor: pointer;
  }
`;

const socialPropertyTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    modalProps: PropTypes.shape({}),
    iconProps: PropTypes.shape({}),
    additionalProps: PropTypes.shape({}),
    className: PropTypes.string,
  })),
  className: PropTypes.string,
};

const shareButtons = {
  EmailShareButton,
  TwitterShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  WhatsappShareButton,
  VKShareButton,
};

const icons: {
  EmailIcon: ReactShareIcon;
  TwitterIcon: ReactShareIcon;
  FacebookIcon: ReactShareIcon;
  LinkedinIcon: ReactShareIcon;
  PinterestIcon: ReactShareIcon;
  WhatsappIcon: ReactShareIcon;
  VKIcon: ReactShareIcon;
} = {
  EmailIcon,
  TwitterIcon,
  FacebookIcon,
  LinkedinIcon,
  PinterestIcon,
  WhatsappIcon,
  VKIcon,
};

export type SocialProperties = Omit<InferProps<
  typeof socialPropertyTypes
>, 'data'> & {
  data?: {
    name: string;
    modalProps: {
      title?: string;
      url: string;
      media?: string;
      quote?: string;
    };
    iconProps: {
      iconBgStyle: { fill: string };
      logoFillColor: string;
      size?: number | undefined;
    };
    additionalProps: {
      'aria-label': string;
    };
    className: string;
    locales?: string[];
    pages?: string[];
    checkMedia?: boolean;
  }[];
  className?: string;
};

type StandardSocialPlatform = 'Facebook' | 'Linkedin' | 'Pinterest'
| 'Twitter' | 'Whatsapp';

const Social = ({ data = [], className }: SocialProperties) => {
  const result = data.map((shareModal, index) => {
    const id = `share-${index}`;
    const buttonKey = `${shareModal.name}ShareButton`;
    const iconKey = `${shareModal.name}Icon`;
    if (Object.keys(shareButtons).includes(buttonKey)
    && Object.keys(icons).includes(iconKey)) {
      const Button = shareButtons[`${
        shareModal.name as StandardSocialPlatform
      }ShareButton`];

      const Icon = icons[`${
        shareModal.name as StandardSocialPlatform
      }Icon`];

      return (
        <Button
          {...shareModal.modalProps}
          key={id}
          className={shareModal.className}
          additionalProps={shareModal.additionalProps}
        >
          <Icon
            {...shareModal.iconProps}
            size={shareModal.iconProps.size ?? 30}
            round
            className={`${shareModal.className}`}
          />
        </Button>
      );
    }
    return (
      <Custom
        key={id}
        modalProps={shareModal.modalProps}
        iconProps={shareModal.iconProps}
      />
    );
  });
  return (
    <SocialWrapper className={className}>
      {result}
    </SocialWrapper>
  );
};

Social.propTypes = socialPropertyTypes;

export default Social;
