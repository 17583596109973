import PropTypes from 'prop-types';
import objectToGetParams from 'react-share/es/utils/objectToGetParams';
import createShareButton from 'react-share/es/utils/createShareButton';
import iconFactory from 'react-share/es/utils/iconFactory';
import React from 'react';

function link(url, { forwardUrl }) {
  return `${forwardUrl}${objectToGetParams({
    url,
  })}`;
}

const Custom = (properties) => {
  const { iconProps, modalProps } = properties;
  const Button = createShareButton(modalProps.name, link, (inProperties) => ({
    ...inProperties,
  }));
  const Icon = iconFactory(modalProps.name, {
    ...iconProps,
  });

  return (
    <Button
      {...modalProps}
      className={modalProps.className}
    >
      <Icon
        {...iconProps}
        className={iconProps.className}
      />
    </Button>
  );
};

Custom.defaultProps = {
  modalProps: {},
  iconProps: {},
};

Custom.propTypes = {
  modalProps: PropTypes.shape({
    url: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
  }),
  iconProps: PropTypes.shape({
    className: PropTypes.string,
  }),
};

export default Custom;
