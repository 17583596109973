import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { Router } from '../routes.mjs';

const Redirect = () => {
  const { asPath } = useRouter();
  const path = asPath.split('?')[0];

  useEffect(() => {
    fetch(
      path,
      { method: 'HEAD', redirect: 'manual' },
    ).then(({ status, headers }) => {
      const location = headers.get('location');

      if (status === 301 && location) {
        Router.replaceRoute(location);
      }
    });
  }, [path]);

  return null;
};

export default Redirect;
