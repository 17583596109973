import styled from '@emotion/styled';
import breakpoints from '../Theme/breakpoints';

const RelatedItemContainer = styled.div`
  @media (min-width: ${breakpoints.sm}px) {
    display: flex;
  }
`;

const RelatedItem = styled.div`
  margin: 0 0 24px;

  @media (min-width: ${breakpoints.sm}px) {
    width: calc((100% / 3) - (16px - (16px / 3)));
    padding: 0 16px 0 0;
    &:nth-of-type(3) {
      padding: 0;
    }
  }
`;

export {
  RelatedItemContainer, RelatedItem,
};
