import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ApolloConsumer } from '@apollo/client';
import ArticleDetail, { ArticleGrid } from '@fuww/library/src/Article';
import { GridCell } from '@fuww/library/src/Grid';
import {
  DesktopAdUnitContainer,
  TabletAdUnitContainer,
  MobileAdUnitContainer,
} from '@fuww/library/src/Advertisement/AdUnitContainer';
import dynamic from 'next/dynamic';
import HiddenLoader from '@fuww/library/src/HiddenLoader';
import Head from '../../Head';
import withQuery from '../../../lib/withQuery';
import throw404 from '../../../lib/throw404';
import getArticleJsonLdData from '../../../lib/getArticleJsonLdData';
import getArticleField from '../../../lib/queries/news/getArticleField';
import articleQuery
from '../../../lib/queries/news/articleQuery.graphql';
import articleByPathQuery
from '../../../lib/queries/news/articleByPathQuery.graphql';
import getArticlesField from '../../../lib/queries/news/getArticlesField';
import getArticleSubjectType from '../../../lib/getArticleSubjectType';
import { articleImageConfigurations } from '../../../lib/imageConfigurations';
import increaseHitsMutation from
'../../../lib/queries/news/increaseHitsMutation.graphql';
import getNewsboardFieldVariables from
'../../../lib/queries/getNewsboardFieldVariables';
import { metaImageDimensions } from '../../../lib/constants';
import BreadCrumbs from './BreadCrumb';
import Main from './Main';
import RelatedNews from './RelatedNews';
import RelatedJobs from './RelatedJobs';
import RelatedProfiles from './RelatedProfiles';
import getDataLayerVariables from './getDataLayerVariables';
import isLoading from '../../../lib/loading';
import { useSiteContext } from '../../SiteContext';
import InView from '../../InView';
import AdUnit from '../../../lib/adunit';
import getSourceSet from '../../../lib/getSourceSet';
import Redirect from '../../Redirect';
import isPositiveSentiment from '../../../lib/isPositiveSentiment';
import splitImageUrls from './splitImageUrls';
import getImageUrlsWithDefaults from './getImageUrlsWithDefaults';

const DynamicSideBar = dynamic(() => import('./SideBar'), {
  ssr: false,
  loading: isLoading,
});

export const ArticleWithLayout = ({
  data,
  variables: { imageConfigurations },
  field,
  ArticleComponent,
  apolloClient,
  showLoader,
  error,
}) => {
  const {
    prefix, hasLocalNews, url, origin, newsboard,
  } = useSiteContext();
  const article = data?.[field];

  useEffect(() => {
    if (article) {
      apolloClient.mutate({
        mutation: increaseHitsMutation,
        variables: {
          ...getNewsboardFieldVariables(prefix, hasLocalNews),
          id: Number(article.id),
          newsboard,
        },
      });
    }
  }, [article, apolloClient, hasLocalNews, prefix, newsboard]);

  if (showLoader) {
    return (
      <ArticleGrid>
        <GridCell
          sm={12}
          lg={8}
          tag="article"
          padding="0px 8px 0"
        >
          <ArticleDetail showLoader />
        </GridCell>
      </ArticleGrid>
    );
  }

  if (!article) {
    if (error) {
      return null;
    }

    return throw404();
  }

  const {
    id, title, creator, keywords, tags,
    insertedAt, description, imageUrls: originalImageUrls,
    partnerContent, scores,
  } = article;
  const imageUrlsWithDefaults = getImageUrlsWithDefaults(
    originalImageUrls,
    imageConfigurations,
  );

  const { metaImageUrl, imageUrls } = splitImageUrls(imageUrlsWithDefaults);

  const articlesField = getArticlesField(prefix, hasLocalNews);

  const { name: author } = { ...creator };

  const robotsMeta = partnerContent
    ? 'index, nofollow' : null;

  const dataLayerVariables = getDataLayerVariables(article);

  const sourceSet = getSourceSet(imageConfigurations.slice(1), imageUrls);

  return (
    <>
      <Head
        title={title}
        description={description}
        imageUrl={metaImageUrl}
        imageDimensions={metaImageDimensions}
        insertedAt={insertedAt}
        author={author}
        robotsMeta={robotsMeta}
        jsonLdData={getArticleJsonLdData(article, url, origin)}
        subjectType={getArticleSubjectType(prefix, hasLocalNews)}
        subjectId={id}
        payload={{ newsboard }}
        addCanonical
        prelude={(
          <link
            rel="modulepreload"
            as="script"
            crossOrigin="anonymous"
            href="https://storage.googleapis.com/media-fashionunited-com/media/details-menu/index.min.js"
          />
        )}
      >
        <script
          async
          type="module"
          crossOrigin="anonymous"
          src="https://storage.googleapis.com/media-fashionunited-com/media/details-menu/index.min.js"
        />
        <script
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `window.dataLayer = window.dataLayer || []; \
  window.dataLayer.push(${JSON.stringify(dataLayerVariables)});`,
          }}
        />
      </Head>
      <ArticleGrid>
        <GridCell
          sm={12}
          lg={12}
          padding="0 0 20px 12px"
        >
          <BreadCrumbs article={article} />
        </GridCell>
        <GridCell
          sm={12}
          lg={8}
          tag="article"
          padding="0px 8px 0"
        >
          <Main
            article={article}
            ArticleComponent={ArticleComponent}
            metaImageUrl={metaImageUrl}
            imageUrls={imageUrls}
            sourceSet={sourceSet}
            showLoader={showLoader}
          />
          {
            isPositiveSentiment(scores) && (
              <InView>
                <RelatedJobs tagSlugs={tags.map(({ slug }) => slug)} />
              </InView>
            )
          }
          <MobileAdUnitContainer>
            <AdUnit
              adUnit="mobile_article_bottom"
              sizes={[[1, 1], [320, 240], [300, 250]]}
              minHeight="240px"
            />
          </MobileAdUnitContainer>
          <TabletAdUnitContainer>
            <AdUnit
              adUnit="tablet_article_bottom"
              sizes={[[1, 1], [300, 250]]}
              minHeight="250px"
            />
          </TabletAdUnitContainer>
          <DesktopAdUnitContainer>
            <AdUnit
              adUnit="article_bottom"
              sizes={[[1, 1], [300, 250]]}
              minHeight="250px"
            />
          </DesktopAdUnitContainer>
          <InView>
            <RelatedNews
              tagSlugs={tags.map(({ slug }) => slug)}
              articleId={id}
              showCategory
              showImage
            />
          </InView>
          <InView>
            <RelatedProfiles newsTags={tags.map(({ slug }) => slug)} />
          </InView>
        </GridCell>
        <GridCell
          sm={12}
          lg={4}
          tag="aside"
          padding="0px 8px 0"
        >
          <InView>
            <DynamicSideBar
              keywords={keywords}
              articlesField={articlesField}
              articleId={id}
            />
          </InView>
        </GridCell>
      </ArticleGrid>
    </>
  );
};

export const articlePropertyTypes = {
  variables: PropTypes.shape({
    imageConfigurations: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  field: PropTypes.string.isRequired,
};

ArticleWithLayout.propTypes = {
  ...articlePropertyTypes,
  data: PropTypes.shape({}),
  ArticleComponent: PropTypes.elementType,
  apolloClient: PropTypes.shape({
    mutate: PropTypes.func,
  }).isRequired,
};
ArticleWithLayout.defaultProps = {
  data: {},
  ArticleComponent: undefined,
};

const ArticleWithLayoutWithApolloClient = React.forwardRef(
  (properties, reference) => (
    <ApolloConsumer>
      {(client) => (
        <ArticleWithLayout
          {...properties}
          apolloClient={client}
          ref={reference}
        />
      )}
    </ApolloConsumer>
  ),
);

const ArticleWithQuery = withQuery(ArticleWithLayoutWithApolloClient, {
  renderAlways: true,
});

const Article = ({
  id,
  path,
  ArticleComponent,
}) => {
  const { prefix, hasLocalNews } = useSiteContext();
  const field = getArticleField(prefix, hasLocalNews, !id);

  return (
    <>
      {id ? null : <Redirect />}
      <ArticleWithQuery
        query={id ? articleQuery : articleByPathQuery}
        field={field}
        variables={{
          ...getNewsboardFieldVariables(prefix, hasLocalNews),
          ...id ? { id } : { path },
          imageConfigurations: articleImageConfigurations,
        }}
        errorMessage="Error loading this news article..."
        ArticleComponent={ArticleComponent}
        loader={<HiddenLoader />}
      />
    </>
  );
};

Article.propTypes = {
  id: PropTypes.number,
  path: PropTypes.string,
  ArticleComponent: PropTypes.elementType,
};

Article.defaultProps = {
  id: null,
  path: null,
  ArticleComponent: undefined,
};

export default Article;
