import { ResponsiveImageConfiguration } from '../../../lib/graphql/api/graphql';
import getDefaultImageUrls from '../../../lib/getDefaultImageUrls';

const getImageUrlsWithDefaults = (
  originalImageUrls: (string | null | undefined)[],
  imageConfigurations: ResponsiveImageConfiguration[],
) => (
  (
    originalImageUrls.every(
      (url) => typeof url === 'string',
    ) ? originalImageUrls : getDefaultImageUrls(imageConfigurations)
  ) as string[]
);

export default getImageUrlsWithDefaults;
