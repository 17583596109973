import breakpoints from '@fuww/library/src/Theme/breakpoints';

const sizes = `
    (min-width: 1100px) 694px,
    (min-width: ${breakpoints.lg}px) 64vw,
    (min-width: ${breakpoints.sm}px) 96vw,
    100vw
`;

export default sizes;
