import React from 'react';
import ImageWithUrls from '../ImageWithUrls';

const Logo = ({ logoUrls, alt }: {
  logoUrls: string[];
  alt: string;
}) => (
  <ImageWithUrls
    sizes="168px"
    imageUrls={logoUrls}
    alt={alt}
    srcSet={`
      ${logoUrls[1]} 168w,
      ${logoUrls[2]} 336w,
    `}
    objectFit="contain"
    padding="0 1rem"
  />
);

export default Logo;
