import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useIntl } from 'react-intl';
import CategoryHeading from '@fuww/library/src/CategoryHeading';
import { JobList, RelatedJobListItem } from '@fuww/library/src/List/JobList';
import SpacingContainer from '@fuww/library/src/SpacingContainer';
import LinkAndAnchor from '../../LinkAndAnchor';
import CompanyLink from '../../Jobs/JobSearch/CompanyLink';
import CityLink from '../../Jobs/JobSearch/CityLink';
import JobCompanyLogo from '../../Jobs/JobSearch/JobCompanyLogo';
import FormattedDate from '../../FormattedDate';
import relatedJobsQuery
  from '../../../lib/queries/jobs/relatedJobsQuery.graphql';
import withQuery from '../../../lib/withQuery';
import { useSiteContext } from '../../SiteContext';
import messages from '../../../lib/messages.mjs';
import {
  relatedJobsLogoImageConfigurations,
} from '../../../lib/imageConfigurations';

const relatedJobPropertyTypes = {
  id: PropTypes.number.isRequired,
  company: PropTypes.shape({
    name: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    logoUrls: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  city: PropTypes.string,
  address: PropTypes.string,
  addressRegion: PropTypes.string,
  buildingName: PropTypes.string,
  postalCode: PropTypes.string,
  publishedAt: PropTypes.string,
  profiles: PropTypes.arrayOf(
    PropTypes.shape({ company: PropTypes.shape({ name: PropTypes.string }) }),
  ),
  cities: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  ),
};

type RelatedJobProperties = InferProps<
  typeof relatedJobPropertyTypes
>;

const RelatedJob = ({
  id,
  company,
  title,
  slug,
  city,
  address,
  addressRegion,
  buildingName,
  postalCode,
  publishedAt,
  profiles,
  cities,
}: RelatedJobProperties) => {
  const { locale, jobsComplete } = useSiteContext();

  return (
    <RelatedJobListItem
      title={(
        <LinkAndAnchor
          route={`job.${locale}`}
          params={{ id: String(id), slug }}
          title={title}
          useRouter={jobsComplete}
        >
          {title}
        </LinkAndAnchor>
      )}
      logo={company.logoUrls[0] && (
        <JobCompanyLogo
          title={title}
          logoUrls={company.logoUrls}
        />
      )}
      company={(
        <CompanyLink
          profiles={profiles}
          company={company}
        />
      )}
      city={(
        <CityLink
          city={city}
          cities={cities}
        />
      )}
      buildingName={buildingName}
      address={address}
      addressRegion={addressRegion}
      postalCode={postalCode}
      publishedAt={<FormattedDate value={publishedAt} />}
    />
  );
};

RelatedJob.propTypes = relatedJobPropertyTypes;

RelatedJob.defaultProps = {
  address: undefined,
  addressRegion: undefined,
  buildingName: undefined,
  postalCode: undefined,
  profiles: [],
  cities: [],
};

const relatedJobsPropertyTypes = {
  data: PropTypes.shape({
    jobs: PropTypes.arrayOf(
      PropTypes.shape(relatedJobPropertyTypes).isRequired,
    ).isRequired,
  }).isRequired,
};

type RelatedJobsProperties = InferProps<
  typeof relatedJobsPropertyTypes
>;

const RelatedJobs = ({ data }: RelatedJobsProperties) => {
  const intl = useIntl();

  const { jobs } = data;

  if (jobs.length === 0) return null;

  return (
    <>
      <CategoryHeading>
        {intl.formatMessage(messages.relatedJobs)}
      </CategoryHeading>
      <SpacingContainer marginBottom="8px" />
      <JobList>
        {jobs.map(
          (job) => (
            <RelatedJob {...job} key={`job-${job.id}`} />
          ),
        )}
      </JobList>
    </>
  );
};

RelatedJobs.propTypes = relatedJobsPropertyTypes;

const RelatedJobsWithQuery = withQuery(RelatedJobs);

const errorMessage = 'Error loading related jobs';

const relatedJobsWithDataPropertyTypes = {
  tagSlugs: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

type RelatedJobsWithDataProperties = InferProps<
  typeof relatedJobsWithDataPropertyTypes
>;

const RelatedJobsWithData = ({
  tagSlugs,
}: RelatedJobsWithDataProperties) => {
  const { jobsLocale } = useSiteContext();

  return (
    <RelatedJobsWithQuery
      query={relatedJobsQuery}
      variables={{
        limit: 3,
        locales: [jobsLocale],
        newsTags: tagSlugs,
        logoConfigurations: relatedJobsLogoImageConfigurations,
      }}
      errorMessage={errorMessage}
    />
  );
};

RelatedJobsWithData.propTypes = relatedJobsWithDataPropertyTypes;

export default RelatedJobsWithData;
