import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import CategoryHeading from '@fuww/library/src/CategoryHeading';
import SpacingContainer from '@fuww/library/src/SpacingContainer';
import PropTypes, { InferProps } from 'prop-types';
import AdaptiveCard from '@fuww/library/src/Cards/AdaptiveCard';
import breakpoints from '@fuww/library/src/Theme/breakpoints';
import { RelatedItemContainer, RelatedItem }
  from '@fuww/library/src/List/RelatedItem';
import relatedProfilesQuery
  from '../../../lib/queries/jobs/relatedProfilesQuery.graphql';
import withQuery from '../../../lib/withQuery';
import { useSiteContext } from '../../SiteContext';
import messages from '../../../lib/messages.mjs';
import { relatedNewsImageConfigurations, wfbAdsLogoImageConfigurations }
  from '../../../lib/imageConfigurations';
import LinkAndAnchor from '../../LinkAndAnchor';
import getProxiedImages from '../../../lib/getProxiedImages';
import ImageWithUrls from '../../ImageWithUrls';
import useSeed from '../../../lib/useSeed';
import seededSampleSize from '../../../lib/seededSampleSize';
import premiumProfilesLimit from '../../../lib/profilesLimit';
import Loader from '../../Loader';
import {
  RelatedProfilesQueryVariables, Newsboard,
} from '../../../lib/graphql/api/graphql';
import Logo from '../../Company/Logo';
import Routes from '../../../routes.mjs';
import { getTargetOrigin } from '../../../lib/redirectRouteToDomain';

const profilesLimit = 3;

const profilePropertyType = PropTypes.shape({
  company: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    logoUrls: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }).isRequired,
  page: PropTypes.shape({
    slug: PropTypes.string,
    imageUrls: PropTypes.arrayOf(PropTypes.string).isRequired,
    mobileImageUrls: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  id: PropTypes.string.isRequired,
});

const relatedProfilesPropertyTypes = {
  data: PropTypes.shape({
    profiles: PropTypes.arrayOf(profilePropertyType).isRequired,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.shape({}),
  relatedProfiles: PropTypes.arrayOf(profilePropertyType).isRequired,
  relatedProfilesError: PropTypes.shape({}),
  relatedProfilesLoading: PropTypes.bool.isRequired,
  variables: PropTypes.shape({}).isRequired,
};

type RelatedProfilesProperties = InferProps<
  typeof relatedProfilesPropertyTypes
> & {
  variables: RelatedProfilesQueryVariables;
};

const RelatedProfiles = ({
  data,
  relatedProfiles,
  variables,
}: RelatedProfilesProperties) => {
  const { newsboard, origin } = useSiteContext();
  const intl = useIntl();
  const seed = useSeed();

  const profiles = useMemo(() => {
    const premiumProfiles = data.profiles;
    const shuffledRelatedProfiles = seededSampleSize(
      [...relatedProfiles], relatedProfiles.length, seed,
    );
    const shuffledPremiumProfiles = seededSampleSize(
      [...premiumProfiles], premiumProfiles.length, seed,
    );

    const allProfiles = [
      ...shuffledRelatedProfiles,
      ...shuffledPremiumProfiles,
    ].slice(0, profilesLimit);

    return allProfiles;
  }, [relatedProfiles, seed, data.profiles]);

  const relatedProfileItems = profiles.map(({
    id,
    company: {
      fullName, logoUrls,
    },
    page: { slug, imageUrls: desktopImageUrls, mobileImageUrls },
  }) => {
    const originalImageUrls = mobileImageUrls[0]
      ? mobileImageUrls : desktopImageUrls;

    const {
      imageUrls,
      srcSet,
    } = getProxiedImages(variables, originalImageUrls);

    const targetPath = Routes.findAndGetUrls(
      'company-profile',
      { slug },
    ).urls.as;

    return slug && (
      <RelatedItem key={id}>
        <LinkAndAnchor
          route="company-profile"
          params={{ slug }}
          href={`${getTargetOrigin(origin)}${targetPath}`}
          useRouter={newsboard === Newsboard.FASHIONUNITED_COM}
        >
          <AdaptiveCard
            image={imageUrls[0] && (
              <ImageWithUrls
                sizes={`
                  (min-width: 1440px) 330px,
                  (min-width: ${breakpoints.lg}px) 24vw,
                  (min-width: ${breakpoints.sm}px) 48vw,
                  100vw
                `}
                srcSet={srcSet}
                imageUrls={imageUrls}
                alt={fullName}
                disableRoundedCorners
              />
            )}
            logo={logoUrls[0] && (
              <Logo logoUrls={logoUrls} alt={fullName} />
            )}
          />
        </LinkAndAnchor>
      </RelatedItem>
    );
  });

  return (
    <>
      <CategoryHeading>
        {intl.formatMessage(messages.relatedCompanies)}
      </CategoryHeading>
      <SpacingContainer />
      <RelatedItemContainer>
        {relatedProfileItems}
      </RelatedItemContainer>
    </>
  );
};

const RelatedProfilesWithPremiumProfilesQuery = withQuery(RelatedProfiles);

const RelatedProfilesWithQuery = withQuery(({
  data,
  error,
  loading,
}: RelatedProfilesWithPremiumProfilesQueryProperties) => {
  const { locale } = useSiteContext();
  const relatedProfilesCount = data.profiles.length;

  const variables = {
    limit: premiumProfilesLimit,
    locales: [locale],
    premium: true,
    imageConfigurations: relatedNewsImageConfigurations,
    logoConfigurations: wfbAdsLogoImageConfigurations,
  };

  return (
    <RelatedProfilesWithPremiumProfilesQuery
      loader={<Loader />}
      query={relatedProfilesQuery}
      relatedProfiles={data.profiles}
      relatedProfilesError={error}
      relatedProfilesLoading={loading}
      variables={variables}
      errorMessage="Error loading premium profiles"
      skip={relatedProfilesCount > profilesLimit}
      error={error}
    />
  );
});

const relatedProfilesWithPremiumProfilesPropertyTypes = {
  data: PropTypes.shape({
    profiles: PropTypes.arrayOf(profilePropertyType).isRequired,
  }).isRequired,
  error: PropTypes.shape({}),
  loading: PropTypes.bool.isRequired,
  newsTags: PropTypes.arrayOf(PropTypes.string),
  variables: PropTypes.shape({}),
};

type RelatedProfilesWithPremiumProfilesQueryProperties = InferProps<
  typeof relatedProfilesWithPremiumProfilesPropertyTypes
>;

const RelatedProfilesPropertyTypes = {
  newsTags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

type RelatedProfilesWithDataProperties = InferProps<
  typeof RelatedProfilesPropertyTypes
>;

const RelatedProfilesWithData = ({
  newsTags,
}: RelatedProfilesWithDataProperties) => {
  const variables = {
    limit: profilesLimit,
    newsTags,
    imageConfigurations: relatedNewsImageConfigurations,
    logoConfigurations: wfbAdsLogoImageConfigurations,
    premium: true,
  };

  return (
    <RelatedProfilesWithQuery
      errorMessage="Error loading related profiles"
      loader={<Loader />}
      newsTags={newsTags}
      query={relatedProfilesQuery}
      variables={variables}
    />
  );
};
RelatedProfilesWithData.propTypes = RelatedProfilesPropertyTypes;

export default RelatedProfilesWithData;
