import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(advancedFormat);
dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);

const formatTags = (tags) => tags.map(({ title }) => title).join(', ');
const formatCreator = (author) => author && author.name;
const formatCategory = (category) => category && category.title;
const formatPublishedWeek = (insertedAt) => dayjs(insertedAt).format('YYYYww');
const formatPublishedIsoWeek = (insertedAt) => {
  const day = dayjs(insertedAt);

  return `${day.format('YYYY')}${day.isoWeek().toString().padStart(2, '0')}`;
};
const formatPublishedMonth = (insertedAt) => dayjs(insertedAt).format('YYYYMM');
const formatPublishedHour = (insertedAt) => dayjs(insertedAt)
  .format('YYYYMMDDHH');
const formatPublished = (insertedAt) => dayjs(insertedAt).toISOString();
const formatFeatured = (featured) => (featured ? 1 : 0);
const countNumberOfWords = (body) => body
  .replaceAll(/<[^>]*>/g, '').trim().split(/\s+/g).length;

const getDataLayerVariables = ({
  id,
  title,
  creator,
  category,
  tags,
  featured,
  insertedAt,
  body,
}) => ({
  id,
  author: formatCreator(creator),
  category: formatCategory(category),
  featured: formatFeatured(featured),
  date_published_isoweek: formatPublishedIsoWeek(insertedAt),
  date_published_month: formatPublishedMonth(insertedAt),
  date_published_week: formatPublishedWeek(insertedAt),
  date_published_hour: formatPublishedHour(insertedAt),
  date_published: formatPublished(insertedAt),
  tags: formatTags(tags),
  social_title: title,
  words: countNumberOfWords(body),
});

export default getDataLayerVariables;
